import React, { Fragment, useEffect, useState } from 'react';
import { FontWeight, Heading1, Heading2, Para } from '../../components/atoms/typography/typography';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InterviewCard from '../../components/organisms/interview-card/interviewCard';
import { useSelector } from 'react-redux';
import { getInterviewsListAction } from '../../redux/states/interview/interview.actions';
import { formatDate, showAlert } from '../../utils/utils';
import Loader from '../../components/molecules/loader/loader';
import { JobStatusType } from '../../models/ui.enum';

export const Home = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const { data: interviewList, loading: loading } = useSelector(
    (state: any) => state?.interview?.interviewList
  );

  const { data: user } = useSelector((state: any) => state?.candidate?.user);

  useEffect(() => {
    dispatch(getInterviewsListAction());
  }, []);

  function handleInterviewCardClick(status: JobStatusType, interviewId: string, inActive: boolean) {
    if (status == JobStatusType.INPROGRESS) {
      navigate(`/interview/${interviewId}`);
    } else if (status == JobStatusType.INVITED) {
      if (!inActive) {
        navigate(`/environment-check/${interviewId}`);
      } else {
        showAlert(
          dispatch,
          'Interview Access Is Not Yet Available',
          'This interview is scheduled for a future date, and access is not currently available. Please check back at the scheduled time to participate in the interview.'
        );
      }
    }
  }
  if (loading) return <Loader />;
  return (
    <Fragment>
      <div className="p-md-5 p-3">
        <div>
          <Heading1>
            <>
              Welcome back! <span className="text-capitalize"> {user?.name || ''}</span>
            </>
          </Heading1>
          <Para textColor="var(--textGray)">You can check all your interviews here</Para>
          <hr className="dotted-separator" />
        </div>
        <div className="row gy-4 pt-4">
          {interviewList?.map((data: any, index: number) => (
            <div className="col-lg-4" key={index}>
              <InterviewCard
                onClick={() => handleInterviewCardClick(data?.status, data?._id, data?.inActive)}
                companyName={data?.jobDetails?.employerDetails?.name}
                role={data?.jobDetails?.role}
                duration={data?.jobDetails?.duration}
                startDate={formatDate(data?.jobDetails?.startDate)}
                endDate={formatDate(data?.jobDetails?.endDate)}
                status={data?.status}
                inActive={data?.inActive}
                logo={data?.jobDetails?.employerDetails?.logo}
              />
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};
