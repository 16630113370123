import { useState, useEffect } from 'react';
import { base64toFile } from '../../utils/utils';
import { useDispatch } from 'react-redux';
import { textToSpeechAction } from '../../redux/states/interview/interview.actions';
import { startInterviewTimer } from '../../utils/data';

interface TextToSpeechComponentProps {
  text: string;
  isRecording?: boolean;
  onComplete?: () => void;
}

function TextToSpeechComponent({ text, isRecording, onComplete }: TextToSpeechComponentProps) {
  const [audioURL, setAudioUrl] = useState<any>(null);
  const dispatch: any = useDispatch();
  const speechText = text;

  useEffect(() => {
    const data = {
      text: speechText,
      modelName: 'B',
      audioConfig: {
        audioEncoding: 'MP3',
        speakingRate: 0.9,
        pitch: 1,
        volumeGainDb: 0
      }
    };

    const convertTextToSpeech = async () => {
      try {
        const response = await dispatch(textToSpeechAction(data));
        const file = base64toFile(response.data, 'audio-file');
        const audioUrl = URL.createObjectURL(file);
        setAudioUrl(audioUrl);
      } catch (error) {
        console.error('Error converting audio:', error);
      }
    };
    if (text) convertTextToSpeech();
  }, [dispatch, speechText]);

  const onAudioEnded = () => {
    console.log('Audio playback finished.');
    if (onComplete) onComplete();
  };

  // Automatically play the audio when audioURL is set
  useEffect(() => {
    if (isRecording) {
      const audioElement = new Audio(audioURL);
      audioElement.pause();
      audioElement.src = '';
    } else if (audioURL) {
      const audioElement = new Audio(audioURL);
      // Attach the event listener
      audioElement.addEventListener('ended', onAudioEnded);
      setTimeout(() => {
        (audioElement.play() as Promise<void>).catch((error) => {
          console.error('Error playing audio:', error);
        });
      }, 1500);
      return () => {
        // Clean up the audio element when the component unmounts.
        audioElement.removeEventListener('ended', onAudioEnded);
        audioElement.pause();
        audioElement.src = '';
        setAudioUrl(null);
      };
    }
  }, [audioURL, isRecording]);

  return <></>;
}

export default TextToSpeechComponent;
