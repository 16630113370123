// need data in this format from api
export const conversationList = {
  nodeJs: [
    {
      conversation:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
      side: 'system'
    },
    {
      conversation:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
      side: 'user'
    },
    {
      conversation:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
      side: 'system'
    },
    {
      conversation:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
      side: 'user'
    },
    {
      conversation:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
      side: 'system'
    },
    {
      conversation:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
      side: 'user'
    },
    {
      conversation:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
      side: 'system'
    },
    {
      conversation:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
      side: 'user'
    },
    {
      conversation:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
      side: 'system'
    },
    {
      conversation:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
      side: 'user'
    },
    {
      conversation:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
      side: 'system'
    }
  ],

  systemReport: {
    coreSkills: [
      {
        name: 'clarity',
        scoring: '4/5',
        feedback: 'Hi this is vishal working as a engineering manager'
      },
      {
        name: 'brevity',
        scoring: '4/5',
        feedback: 'Hi this is vishal working as a engineering manager'
      },
      {
        name: 'clarity',
        scoring: '4/5',
        feedback: 'Hi this is vishal working as a engineering manager'
      },
      {
        name: 'brevity',
        scoring: '4/5',
        feedback: 'Hi this is vishal working as a engineering manager'
      }
    ],
    keyTechnicalSkills: [
      {
        name: 'clarity',
        scoring: '4/5',
        feedback: 'Hi this is vishal working as a engineering manager'
      },
      {
        name: 'brevity',
        scoring: '4/5',
        feedback: 'Hi this is vishal working as a engineering manager'
      }
    ],
    keyBeharioualSkills: [
      {
        feedback: 'Hi this is vishal working as a engineering manager'
      },
      {
        feedback: 'Hi this is vishal working as a engineering manager'
      }
    ],
    keyCommunicationSkills: [
      {
        feedback: 'Hi this is vishal working as a engineering manager'
      },
      {
        feedback: 'Hi this is vishal working as a engineering manager'
      }
    ]
  }
};

export const voizoSignatureSound =
  'https://voizo-public.s3.ap-south-1.amazonaws.com/Audio/voizo-tone.mp3';
export const startInterviewTimer = {
  countdownTime: 15,
  waitingTime: 1500
};

export const interviewSnapshotTimer = {
  snapshotTimer: 60 * 1000
};
