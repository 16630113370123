import { RefObject } from 'react';
import { JobStatusType } from '../models/ui.enum';
import { removeData } from '../redux/services/handleData/localStorage';
import {
  ALERT_MODAL_HEADING,
  ALERT_MODAL_ON,
  ALERT_MODAL_PARA
} from '../redux/states/ui/ui.actions';

type ChatWrapperRefType = RefObject<HTMLDivElement>;

export function onLogout() {
  removeData('token');
  window.location.reload();
}

export function getNameInitials(fullName: string): string {
  if (!fullName) {
    return '';
  }
  const words = fullName?.trim().split(' ');
  const initials = words
    ?.slice(0, 2)
    ?.map((word) => word.charAt(0).toUpperCase())
    .join('');
  return initials;
}

export function formatSecondsToMinutesAndSeconds(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  const secondsStr = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  return `${minutesStr}:${secondsStr} min`;
}

export function formatDate(inputDateStr: string) {
  const inputDate = new Date(inputDateStr);

  // Define options for formatting the date
  const options: any = { year: 'numeric', month: 'short', day: 'numeric' };

  // Convert the date to the desired format
  const formattedDate = inputDate.toLocaleDateString('en-US', options);

  return formattedDate;
}

export function base64toFile(base64Data: any, fileName: any, fileType?: string) {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const file = new File(byteArrays, fileName, {
    type: fileType == 'image' ? 'image/png' : 'application/octet-stream'
  });
  return file;
}

export function playAudio(audioUrl: string) {
  const audio = new Audio(audioUrl);

  audio
    .play()
    .then(() => {})
    .catch((error) => {
      console.error('Error playing audio:', error);
    });
}

export function autoScrollToBottom(chatWrapper: ChatWrapperRefType) {
  setTimeout(() => {
    chatWrapper.current?.scrollTo({
      top: chatWrapper.current.scrollHeight,
      behavior: 'smooth'
    });
  }, 1000);
}

export function showAlert(dispatch: any, title: string, description?: string) {
  dispatch({ type: ALERT_MODAL_ON });
  dispatch({ type: ALERT_MODAL_HEADING, payload: title });
  dispatch({ type: ALERT_MODAL_PARA, payload: description });
}
