import { combineReducers } from 'redux';
import interviewReducer from './states/interview/interview.reducer';
import candidatesReducer from './states/candidates/candidates.reducer';
import uiReducer from './states/ui/ui.reducer';

const rootReducer = combineReducers<any>({
  interview: interviewReducer,
  candidate: candidatesReducer,
  ui: uiReducer
});

export default rootReducer;
