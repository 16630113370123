import { Player } from '@lottiefiles/react-lottie-player';
import React, { useRef } from 'react';

interface LottiefilesAnimationProps {
  src: object;
  styles: React.CSSProperties;
  autoplay: boolean;
  loop: boolean;
  speed?: number;
}

function LottiefilesAnimation({ src, styles, autoplay, loop, speed }: LottiefilesAnimationProps) {
  const player: React.RefObject<Player> = useRef(null);

  return (
    <Player
      ref={player}
      autoplay={autoplay}
      loop={loop}
      speed={speed}
      controls={true}
      src={src}
      style={styles}
    />
  );
}

export default LottiefilesAnimation;
