import React, { useEffect } from 'react';
import { getNameInitials, onLogout } from '../utils/utils';
import { Caption, FontWeight, Heading1, Heading3 } from './atoms/typography/typography';
import { useNavigate } from 'react-router-dom';
import Logo from './../assets/logo.svg';
import { CaretDown } from 'phosphor-react';
import { useDispatch } from 'react-redux';
import { getUserAction } from '../redux/states/candidates/candidates.actions';
import { useSelector } from 'react-redux';
import { loadData } from '../redux/services/handleData/localStorage';

export default function Navbar() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const { data: user } = useSelector((state: any) => state?.candidate?.user);

  useEffect(() => {
    if (loadData('token')) {
      dispatch(getUserAction());
    }
  }, []);

  function handleAuth() {
    if (loadData('token')) {
      return (
        <>
          <div className="dropdown">
            <div
              className=" d-flex align-items-center"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="name-initials-avatar me-2 text-center">
                {getNameInitials(user?.name ?? '')}
              </div>
              <div className="me-2">
                <Heading3 fontWeight={FontWeight.MEDIUM} className="text-capitalize">
                  {user?.name}
                </Heading3>
                <Caption textColor="var(--textGray)">{user?.email}</Caption>
              </div>
              <CaretDown size={18} weight="fill" color="var(--textGray)" className="ms-2" />
            </div>

            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item cursor-na disabled" href="#">
                  View Profile
                </a>
              </li>
              <li
                onClick={() => {
                  navigate('/');
                  onLogout();
                }}
              >
                <a className="dropdown-item text-danger" href="#">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Heading1 className="navbar-brand">
          <img
            src={Logo}
            onClick={() => {
              navigate('/');
            }}
            role="button"
          />
        </Heading1>

        <div id="navbarSupportedContent">
          <div className="mx-auto "></div>
          <form>{handleAuth()}</form>
        </div>
      </div>
    </nav>
  );
}
