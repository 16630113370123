import React from 'react';
import './styles.css';
import { Caption, FontWeight, Heading2, Heading3, Para } from '../../atoms/typography/typography';
import { CheckCircle, CheckSquare, CheckSquareOffset, Watch } from 'phosphor-react';
import { JobStatusType } from '../../../models/ui.enum';
import { StandardBtn } from '../../molecules/buttons/buttons';
import { LOGO_BASE_URL } from '../../../utils/config';

interface InterviewCardProps {
  onClick?: () => any;
  duration?: string;
  role?: string;
  companyName?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
  inActive?: boolean;
  logo?: string;
}

export default function InterviewCard({
  onClick,
  duration,
  role,
  companyName,
  startDate,
  endDate,
  status,
  inActive,
  logo
}: InterviewCardProps) {
  const logoUrl = `${LOGO_BASE_URL}/${logo}`;
  return (
    <div
      className={status == JobStatusType.EXPIRED ? 'interview-card gainsboro' : 'interview-card'}
      role="button"
      onClick={onClick}
    >
      <div className="p-4">
        <div className="d-flex">
          {logoUrl ? <img src={logoUrl} alt="User Logo" className="logo me-2" /> : <></>}
          <Heading2 fontWeight={FontWeight.SEMIBOLD}>{companyName ?? ''}</Heading2>
        </div>
        <Heading2 fontWeight={FontWeight.EXTRA_LIGHT} textColor="var(--textGray)">
          {role ?? ''}
        </Heading2>

        <Para textColor="var(--textGray)">
          <>
            <Watch size={18} />
            {duration ?? ''} min
          </>
        </Para>

        <div className="d-flex mt-3">
          <Heading3 textColor="var(--textGray)" className="me-1">
            Validity:
          </Heading3>
          <Heading3 fontWeight={FontWeight.SEMIBOLD}>{`${startDate} – ${endDate}`}</Heading3>
        </div>
      </div>
      <div className="p-3 text-center">
        {status == JobStatusType.INPROGRESS && (
          <StandardBtn
            handleBtnClick={() => {}}
            btnSize="sm"
            btnTextColor="var(--primary)"
            btnBorderColor="var(--primary"
            className="container-fluid"
          >
            Resume Interview
          </StandardBtn>
        )}
        {status == JobStatusType.INVITED && inActive && (
          <Heading3 fontWeight={FontWeight.SEMIBOLD} textColor={'var(--primary)'}>
            <>{`Interview starts on ${startDate}`}</>
          </Heading3>
        )}
        {status == JobStatusType.INVITED && !inActive && (
          <StandardBtn
            handleBtnClick={() => {}}
            btnSize="sm"
            btnTextColor="var(--white)"
            btnBorderColor="var(--green)"
            className="container-fluid"
            btnBackgroundColor="var(--green)"
          >
            Start Interview
          </StandardBtn>
        )}
        {status == JobStatusType.COMPLETED && (
          <Heading3 fontWeight={FontWeight.SEMIBOLD} textColor={'var(--green)'}>
            <>
              Completed
              <CheckCircle size={24} weight="fill" />
            </>
          </Heading3>
        )}
        {status == JobStatusType.EXPIRED && (
          <Heading3 fontWeight={FontWeight.SEMIBOLD} textColor={'var(--textGray)'}>
            Expired 🚫
          </Heading3>
        )}
      </div>
    </div>
  );
}
