const environmentConfig = {
  demo: process.env.REACT_APP_DEMO_BASE_URL,
  stage: process.env.REACT_APP_STAGE_BASE_URL,
  production: process.env.REACT_APP_PROD_BASE_URL
};

const websiteUrl = {
  demo: 'https://demo.candidate.voizo.ai/',
  stage: 'https://stage.candidate.voizo.ai/',
  production: 'https://candidate.voizo.ai/'
};

export const BASE_URL =
  (environmentConfig as Record<string, string | undefined>)[
    process.env.REACT_APP_ENV || 'https://beta-stage-api.voizo.ai'
  ] || 'https://beta-stage-api.voizo.ai';

export const WEBSITE_URL =
  (websiteUrl as Record<string, string | undefined>)[
    process.env.REACT_APP_ENV || 'https://stage.candidate.voizo.ai/'
  ] || 'https://stage.candidate.voizo.ai/';

export const LOGO_BASE_URL = 'https://voizo-public.s3.ap-south-1.amazonaws.com';
