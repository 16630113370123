import { Dispatch } from 'redux';
import { handleFailure, handleRequest, handleSuccess } from '../../services/handleData/handleData';
import {
  GET_CONVERSATION_FAILED,
  GET_CONVERSATION_REQUEST,
  GET_CONVERSATION_SUCCESS,
  GET_INTERVIEW_BY_ID_FAILED,
  GET_INTERVIEW_BY_ID_REQUEST,
  GET_INTERVIEW_BY_ID_SUCCESS,
  GET_INTERVIEW_FAILED,
  GET_INTERVIEW_REQUEST,
  GET_INTERVIEW_SUCCESS,
  INTERVIEW_SNAPSHOTS_FAILED,
  INTERVIEW_SNAPSHOTS_REQUEST,
  INTERVIEW_SNAPSHOTS_SUCCESS,
  NEXT_CONVERSATION_FAILED,
  NEXT_CONVERSATION_REQUEST,
  NEXT_CONVERSATION_SUCCESS,
  START_CONVERSATION_FAILED,
  START_CONVERSATION_REQUEST,
  START_CONVERSATION_SUCCESS,
  TEXT_TO_SPEECH_FAILED,
  TEXT_TO_SPEECH_REQUEST,
  TEXT_TO_SPEECH_SUCCESS
} from './interview.constant';
import {
  getConversationService,
  getInterviewListService,
  getInterviewService,
  interviewSnapshotsService,
  nextConversationService,
  startConversationService,
  textToSpeechService
} from './interview.services';

export const getInterviewsListAction = () => async (dispatch: Dispatch) => {
  dispatch(handleRequest(GET_INTERVIEW_REQUEST));
  try {
    const res = await getInterviewListService();
    dispatch(handleSuccess(GET_INTERVIEW_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_INTERVIEW_FAILED, ex?.message));
    return ex;
  }
};

export const getInterviewAction = (interviewId: string) => async (dispatch: Dispatch) => {
  const urlParams = `?id=${interviewId}`;

  dispatch(handleRequest(GET_INTERVIEW_BY_ID_REQUEST));
  try {
    const res = await getInterviewService(urlParams);
    dispatch(handleSuccess(GET_INTERVIEW_BY_ID_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_INTERVIEW_BY_ID_FAILED, ex?.message));
    return ex;
  }
};

export const startConversationAction = (interviewId: any) => async (dispatch: Dispatch) => {
  const urlParams = `?id=${interviewId}`;
  const data = {
    connectionInformation: {
      internetSpeed: '30mbps',
      micStatus: 'WORKING',
      bacgroundNoise: '5db',
      speakerStatus: 'WORKING'
    }
  };

  dispatch(handleRequest(START_CONVERSATION_REQUEST, true));
  try {
    const res = await startConversationService(data, urlParams);
    dispatch(handleSuccess(START_CONVERSATION_SUCCESS, res?.data));
    return res;
  } catch (ex) {
    dispatch(handleFailure(START_CONVERSATION_FAILED, ex));
    return ex;
  }
};

export const nextConversationAction =
  (interviewId: string, data: any) => async (dispatch: Dispatch) => {
    const urlParams = `?interviewId=${interviewId}`;

    dispatch(handleRequest(NEXT_CONVERSATION_REQUEST, true));
    try {
      const res = await nextConversationService(data, urlParams);
      dispatch(handleSuccess(NEXT_CONVERSATION_SUCCESS, res?.data));
      return res;
    } catch (ex) {
      dispatch(handleFailure(NEXT_CONVERSATION_FAILED, ex));
      return ex;
    }
  };

export const getConversationAction = (interviewId: string) => async (dispatch: Dispatch) => {
  const urlParams = `?interviewId=${interviewId}`;
  dispatch(handleRequest(GET_CONVERSATION_REQUEST));
  try {
    const res = await getConversationService(urlParams);
    dispatch(handleSuccess(GET_CONVERSATION_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_CONVERSATION_FAILED, ex?.message));
    return ex;
  }
};

export const textToSpeechAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(handleRequest(TEXT_TO_SPEECH_REQUEST, true));
  try {
    const res = await textToSpeechService(data);
    dispatch(handleSuccess(TEXT_TO_SPEECH_SUCCESS, res?.data));
    return res;
  } catch (ex) {
    dispatch(handleFailure(TEXT_TO_SPEECH_FAILED, ex));
    return ex;
  }
};

export const interviewSnapshotsAction =
  (data: any, interviewId: string) => async (dispatch: Dispatch) => {
    const urlParams = `?interviewId=${interviewId}`;
    dispatch(handleRequest(INTERVIEW_SNAPSHOTS_REQUEST, true));
    try {
      const res = await interviewSnapshotsService(data, urlParams);
      dispatch(handleSuccess(INTERVIEW_SNAPSHOTS_SUCCESS, res?.data));
      return res;
    } catch (ex) {
      dispatch(handleFailure(INTERVIEW_SNAPSHOTS_FAILED, ex));
      return ex;
    }
  };
