import { useState } from 'react';
import { PrimaryTextfield } from '../../components/molecules/textfield/textfield';
import { PrimaryButton } from '../../components/molecules/buttons/buttons';
import { Heading1, Para } from '../../components/atoms/typography/typography';
import { ButtonType } from '../../models/ui.enum';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword, signUpUser } from '../../firebase';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getLoaderValue } from '../../redux/states/ui/ui.services';
import { useDispatch } from 'react-redux';
import { LOADING_OFF, LOADING_ON } from '../../redux/states/ui/ui.actions';
import { Eye, EyeSlash } from 'phosphor-react';
import { showAlert } from '../../utils/utils';

export default function ResetPassword() {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const { state } = useLocation();

  function togglePasswordView() {
    setShowPassword(!showPassword);
  }
  function toggleConfirmPasswordView() {
    setShowConfirmPassword(!showConfirmPassword);
  }

  const isLoading = useSelector(getLoaderValue);
  const dispatch = useDispatch();

  function handlePassword(value: any) {
    const newPass = value;
    setPassword(newPass);

    const lowerCase = /[a-z]/g;
    const upperCase = /[A-Z]/g;
    const numbers = /[0-9]/g;

    if (newPass.length < 8) {
      setErrorMessage('Password should be at least 8 characters long!');
    } else if (!newPass.match(lowerCase)) {
      setErrorMessage('Password should contain at least one LowerCase letter!');
    } else if (!newPass.match(upperCase)) {
      setErrorMessage('Password should contain at least one UpperCase letter!');
    } else if (!newPass.match(numbers)) {
      setErrorMessage('Password should contain at least one Number!');
    } else {
      setErrorMessage('');
    }
  }

  const ERROR_CODES = {
    WRONG_OOB_CODE: 'auth/internal-error'
  };

  function handleError(errorCode: string) {
    switch (errorCode) {
      case ERROR_CODES.WRONG_OOB_CODE:
        showAlert(dispatch, 'Something went wrong');
        break;
      default:
        showAlert(dispatch, 'An unexpected error occurred during sign up');
    }
  }

  function handleOnSubmit() {
    if (errorMessage) return;

    if (password !== confirmPassword) {
      setConfirmPasswordError('Confirm password should be same as the password field');
      return;
    } else {
      setConfirmPasswordError('');
    }

    dispatch({ type: LOADING_ON });
    resetPassword(state?.oobCode, password)
      .then((res: any) => {
        dispatch({ type: LOADING_OFF });
        if (res?.code) {
          handleError(res?.code);
          return;
        }
        toast.success('Password Changes Successfully');
        navigate('/');
      })
      .catch((e: any) => {
        console.log(e);
        dispatch({ type: LOADING_OFF });
        toast.success('Something went wrong');
      });
  }

  return (
    <div className="row justify-content-center text-center mt-4 p-4">
      <div className="col-lg-4">
        <form
          onSubmit={(e) => {
            e?.preventDefault();
            handleOnSubmit();
          }}
        >
          <div className="main-content-wrapper">
            <Heading1 className="mb-2">Reset your Password</Heading1>
            <Para textColor="var(--textGray)" className="mb-5">
              Please enter new password to change password.
            </Para>
            <br />

            <div className="text-start">
              <PrimaryTextfield
                onChange={handlePassword}
                label="New Password"
                placeholder="Password"
                value={password}
                fieldName=""
                type={showPassword ? 'text' : 'password'}
                required
                error={errorMessage}
                suffixIcon={showPassword ? <Eye size={24} /> : <EyeSlash size={24} />}
                onSuffixIconClick={togglePasswordView}
              />

              <PrimaryTextfield
                onChange={(value) => setConfirmPassword(value)}
                label="Confirm Password"
                placeholder="Re-enter Password"
                value={confirmPassword}
                fieldName=""
                type={showConfirmPassword ? 'text' : 'password'}
                error={confirmPasswordError}
                required
                suffixIcon={showConfirmPassword ? <Eye size={24} /> : <EyeSlash size={24} />}
                onSuffixIconClick={toggleConfirmPasswordView}
              />
              <br />
              <PrimaryButton
                className="w-100"
                type={ButtonType.SUBMIT}
                handleBtnClick={() => {}}
                isLoading={isLoading}
                loadingText="Please wait"
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
