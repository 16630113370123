// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
  sendEmailVerification,
  updateProfile,
  getIdTokenResult,
  sendPasswordResetEmail,
  applyActionCode,
  confirmPasswordReset
} from 'firebase/auth';
import { toast } from 'react-toastify';
import { saveData } from './redux/services/handleData/localStorage';
import { WEBSITE_URL } from './utils/config';

let firebaseConfig = {
  apiKey: 'AIzaSyCXZZePQVufF4M1-7pDoRjXuMoTWEHslnw',
  authDomain: 'candidate-stage.firebaseapp.com',
  projectId: 'candidate-stage',
  storageBucket: 'candidate-stage.appspot.com',
  messagingSenderId: '496593924852',
  appId: '1:496593924852:web:3bd979161fd41de10924a1',
  measurementId: 'G-VLZGK8FPQB'
};

if (process.env.REACT_APP_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyD9b4LI1Igz9dt-EiqsNltn8xnfooWHT5U',
    authDomain: 'candidate-voizo.firebaseapp.com',
    projectId: 'candidate-voizo',
    storageBucket: 'candidate-voizo.appspot.com',
    messagingSenderId: '386482377700',
    appId: '1:386482377700:web:bae35ebbb86207c1762df1',
    measurementId: 'G-8JQ7VR5T8N'
  };
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

// https://firebase.google.com/docs/auth/web/password-auth

async function signUpUser(email: string, password: string, userName: string) {
  try {
    const userCredential: UserCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user: User = userCredential.user;

    if (user && user.emailVerified === false) {
      await sendEmailVerification(user, {
        url: WEBSITE_URL,
        handleCodeInApp: true
      });
      await updateProfile(user, {
        displayName: userName
      });
    }
    return user;
  } catch (error: any) {
    return error;
  }
}

async function signInUser(email: string, password: string) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user: User = userCredential.user;
    if (user && user.emailVerified === false) {
      await sendEmailVerification(user, {
        url: WEBSITE_URL,
        handleCodeInApp: true
      });
    }
    return user;
  } catch (error: any) {
    return error;
  }
}

async function refreshUserToken(user: any) {
  try {
    const refreshedToken = await user.getIdToken(true); // Force token refresh
    console.log(refreshedToken);
    saveData('token', refreshedToken);
  } catch (error) {
    console.error('Error refreshing token:', error);
  }
}

function getTokenExpirationTime(userTokenInfo: any): number {
  const expirationDate = new Date(userTokenInfo.expirationTime);
  return expirationDate.getTime() / 1000;
}

async function getTokenData() {
  auth.onAuthStateChanged(async (user) => {
    try {
      if (!user) return;
      const userTokenInfo = await getIdTokenResult(user);
      const currentTime = new Date().getTime() / 1000;
      const tokenExpirationTime = getTokenExpirationTime(userTokenInfo);
      const timeUntilExpiration = tokenExpirationTime - currentTime;
      const timeToRefresh = timeUntilExpiration - 300; // 5 minutes
      const refreshInterval = Math.max(0, timeToRefresh * 1000);
      if (timeToRefresh < 0) {
        await refreshUserToken(user);
      }

      setTimeout(getTokenData, refreshInterval);
    } catch (error) {
      console.error('Error in onAuthStateChanged:', error);
    }
  });
}

async function forgotPassword(email: string) {
  try {
    const data = await sendPasswordResetEmail(auth, email, {
      url: WEBSITE_URL,
      handleCodeInApp: true
    });
    return data;
  } catch (error: any) {
    return error;
  }
}

async function verifyEmail(code: string) {
  try {
    await applyActionCode(auth, code);
    toast.success('Email successfully verified!');
  } catch (error) {
    toast.error('Error verifying email');
  }
}

async function resetPassword(oobCode: string, newPassword: string) {
  try {
    const data = await confirmPasswordReset(auth, oobCode, newPassword);
    return data;
  } catch (error) {
    return error;
  }
}

// signOut(auth).then(() => {
//     // Sign-out successful.
// }).catch((error) => {
//     console.log(error)
//     // An error happened.
// });

export {
  auth,
  signUpUser,
  signInUser,
  signOut,
  analytics,
  getTokenData,
  forgotPassword,
  verifyEmail,
  resetPassword
};
