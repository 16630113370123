import React from 'react';

interface ProgressLoaderDto {
  count: number;
}

export default function ProgressLoader({ count }: ProgressLoaderDto) {
  const gradient = `linear-gradient(to right, white ${count}%, var(--primary) ${count}%)`;

  return (
    <div className="progress-bar-container mt-4 position-relative">
      <p
        className="ans"
        key={gradient}
        style={{
          background: gradient,
          WebkitBackgroundClip: `text`,
          backgroundClip: `text`,
          color: `transparent`,
          zIndex: `1`
        }}
      >
        Submitting your Answer....
      </p>
      <div className="progress-bar position-absolute" style={{ width: `${count}%` }}></div>
    </div>
  );
}
