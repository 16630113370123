import { useCallback, useEffect, useRef, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../../../components/molecules/buttons/buttons';
import { ArrowClockwise, CaretRight, Microphone } from 'phosphor-react';
import './style.css';
import { FontWeight, Heading2, Para } from '../../../components/atoms/typography/typography';
import NoInternet from '../../../assets/no-internet.svg';
import { AlertModal, InfoModal } from '../../../components/molecules/modal/modal';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { autoScrollToBottom, base64toFile } from '../../../utils/utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getConversationAction,
  interviewSnapshotsAction,
  nextConversationAction,
  startConversationAction
} from '../../../redux/states/interview/interview.actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { JobStatusType } from '../../../models/ui.enum';
import Loader from '../../../components/molecules/loader/loader';
import TextToSpeechComponent from '../../../components/audio-player/textToSpeech';
import useOnlineStatus from '../../../hooks/useOnlineStatus';
import { breakLines } from '../../../utils/ui';
import VoiceAi from './../../../assets/voice-recorder.json';
import Webcam from 'react-webcam';
import { interviewSnapshotTimer } from '../../../utils/data';
import PartyPopper from '../../../assets/party-popper.json';
import LottiefilesAnimation from '../../../components/molecules/lottiefiles-animations/lottiefilesAnimations';
import ProgressLoader from './progressLoader';

interface QuestionDto {
  active: boolean;
  count: number;
  quest: string;
  att?: string;
}

interface InterviewDataDto {
  conversationList: any;
  status: JobStatusType;
  questionList: QuestionDto[];
}

export default function VoizoChat() {
  const chatWrapperRef = useRef<HTMLDivElement | null>(null);
  const dispatch: any = useDispatch();
  const { interviewId } = useParams();
  const [modalChatContent, setModalChatContent] = useState<any>(null);
  const [responseLoader, setResponseLoader] = useState<boolean>(false);
  const [startCoversationLoader, setStartCoversationLoader] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState(false);
  const [blobSize, setBlobSize] = useState(0);
  const [isTimeout, setIsTimeout] = useState<boolean>(false);
  const [interviewData, setInterviewData] = useState<InterviewDataDto | null>();
  const [animationName, setAnimationName] = useState<string>('');

  const { loading: loading } = useSelector((state: any) => state?.interview?.conversationList);
  const webcamRef = useRef<Webcam | null>(null);
  const navigate = useNavigate();

  const [progressValue, setProgressValue] = useState<number>(0);
  const [isRedo, setRedo] = useState<boolean>(false);
  const [progressLoaderValue, setProgressLoaderValue] = useState<number>(0);
  const [userAnswer, setUserAnswer] = useState<any>();

  function showAnimation() {
    setAnimationName('slideAnimation');
    setTimeout(() => {
      setAnimationName('');
    }, 2500);
  }

  function getProgressValue(questionsArray: QuestionDto[]) {
    let arry = questionsArray;
    for (let i = 0; i < arry.length; i++) {
      if (arry[i].hasOwnProperty('active')) {
        if (arry[i].active === true) {
          setProgressValue(i + 1);
        }
        if (i === arry.length - 1 && arry[i].active === false) {
          setProgressValue(i + 1);
        }
      }
    }
  }

  const capture = useCallback(async () => {
    if (webcamRef.current) {
      const pictureSrc = webcamRef.current.getScreenshot();
      if (pictureSrc) {
        const base64Content = pictureSrc.split(',')[1];
        const convertedImage = base64toFile(base64Content, 'file', 'image');
        const formData = new FormData();
        formData.append('file', convertedImage);
        try {
          await dispatch(interviewSnapshotsAction(formData, interviewId ?? ''));
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [webcamRef]);

  const takePictures = () => {
    setInterval(capture, interviewSnapshotTimer.snapshotTimer);
  };

  const isOnline = useOnlineStatus();

  useEffect(() => {
    autoScrollToBottom(chatWrapperRef);
  }, []);

  async function getConversation() {
    try {
      const res = await dispatch(getConversationAction(interviewId ?? ''));
      const data = {
        conversationList: res?.data?.userConversation,
        status: res?.data?.status,
        questionList: res?.data?.questions
      };
      getProgressValue(data.questionList);
      setInterviewData(data);
    } catch (e) {
      console.log(e);
    }
  }

  async function startConversation() {
    setStartCoversationLoader(true);
    try {
      await dispatch(startConversationAction(interviewId ?? ''));
      getConversation();
      setStartCoversationLoader(false);
    } catch (e) {
      setStartCoversationLoader(false);
    }
  }

  useEffect(() => {
    startConversation();
    takePictures();
  }, []);

  function fillProgressLoader() {
    setProgressLoaderValue((currentCount) => {
      return currentCount < 90 ? currentCount + 10 : currentCount;
    });
  }

  async function getNextConversation(formData: FormData) {
    setResponseLoader(true);
    setProgressLoaderValue(0);
    const interval = setInterval(fillProgressLoader, 1000);
    try {
      const res = await dispatch(nextConversationAction(interviewId ?? '', formData));

      if (!res) {
        setUserAnswer(formData);
        setIsTimeout(true);
        return;
      }
      clearInterval(interval);
      setResponseLoader(false);
      setIsRecording(false);
      autoScrollToBottom(chatWrapperRef);
      setTimeout(() => {
        const data = {
          conversationList: res?.data?.userConversation,
          status: res?.data?.status,
          questionList: res?.data?.questions
        };
        getProgressValue(data.questionList);
        setInterviewData(data);
      }, 1100);

      showAnimation();
    } catch (e) {
      clearInterval(interval);
      console.log(e);
      setResponseLoader(false);
    }
  }

  const recorderControls = useAudioRecorder();

  const addAudioElement = async (blob: Blob) => {
    const url = URL.createObjectURL(blob);
    let audio = document.createElement('audio');

    const previousAudio = document.querySelector('audio');

    if (previousAudio) {
      document.body.removeChild(previousAudio);
    }

    audio.src = url;
    audio.controls = true;

    document.body.appendChild(audio);

    if (blob.size > 0 && blob.size !== blobSize) {
      setBlobSize(blob.size);
      const formData = new FormData();
      formData.append('file', blob, 'audio.webm');

      await getNextConversation(formData);
    }
  };

  function submitAnswerCard() {
    return (
      <div className="recording-card-wrapper">
        {recorderControls.isRecording ? (
          <div className="d-flex justify-content-between align-items-center">
            <SecondaryButton
              handleBtnClick={() => {
                setRedo(true);
              }}
              className={`response`}
              btnTextColor="var(--primary)"
            >
              <>
                <ArrowClockwise size={20} weight="fill" className="me-2" />
                {'Redo'}
              </>
            </SecondaryButton>

            <LottiefilesAnimation
              loop
              autoplay
              src={VoiceAi}
              styles={{ height: '100px', width: '100px' }}
              speed={4}
            />

            <PrimaryButton
              handleBtnClick={recorderControls.stopRecording}
              className=" response"
              isLoading={responseLoader}
              loadingText="Please wait"
              isDisabled={responseLoader}
            >
              <>
                {'Submit'}
                <CaretRight size={20} className="ms-2" />
              </>
            </PrimaryButton>
          </div>
        ) : (
          <div className="my-2 audio-recorder-button-wrapper d-flex">
            <PrimaryButton
              className="w-100 answer"
              handleBtnClick={() => {
                recorderControls.startRecording();
                setIsRecording(true);
              }}
              isLoading={responseLoader}
              loadingText="Please wait"
              isDisabled={responseLoader}
            >
              <div>
                <Microphone size={20} weight="fill" className="me-2" color="var(--white)" />
                {'Click to Answer'}
              </div>
            </PrimaryButton>
            <AudioRecorder
              onRecordingComplete={addAudioElement}
              recorderControls={recorderControls}
              audioTrackConstraints={{
                noiseSuppression: true,
                echoCancellation: true
              }}
              onNotAllowedOrFound={(err: any) => console.table(err)}
              downloadOnSavePress={false}
              downloadFileExtension="webm"
              showVisualizer={true}
            />
          </div>
        )}
      </div>
    );
  }

  function getProgressBarWidth() {
    if (interviewData) {
      return (progressValue / interviewData?.questionList?.length ?? 0) * 100 + '%';
    }
    return '0%';
  }

  function getProgressLabel(index: number, questionData: QuestionDto) {
    const followupQuestionCount = questionData?.count > 1 ? questionData?.count - 1 : null;
    if (followupQuestionCount && questionData?.active) {
      return index + 1 + `.${followupQuestionCount}`;
    }
    return index + 1;
  }

  const abortController = new AbortController();
  const { signal } = abortController;

  const unload = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', unload, { signal });
    return () => {
      window.removeEventListener('beforeunload', unload);
    };
  }, [signal]);

  if (loading || startCoversationLoader) return <Loader />;
  return (
    <div>
      <div className="custom-progress-bar position-relative">
        <div
          className="progress my-4 position-absolute"
          style={{ height: '6px', backgroundColor: 'var(--lightestGray)' }}
        >
          <div
            className="progress-bar"
            id="progress-bar-id"
            role="progressbar"
            style={{ width: getProgressBarWidth(), backgroundColor: 'var(--primary)' }}
            aria-valuenow={progressValue}
            aria-valuemin={0}
            aria-valuemax={interviewData?.questionList?.length}
          ></div>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <div className="circle invisible"></div>

          {interviewData?.questionList?.map((data: QuestionDto, index: number) => (
            <div key={index} className={`circle ${index <= progressValue - 1 && 'completed'} `}>
              <Para
                className={`attribute-name position-absolute text-nowrap ${
                  data?.active && 'active-step'
                }`}
              >
                {getProgressLabel(index, data)}
              </Para>
            </div>
          ))}
        </div>
      </div>

      <div className="row justify-content-center chat-wrapper" ref={chatWrapperRef}>
        <div className="col-lg-7">
          {interviewData?.status != JobStatusType.COMPLETED ? (
            <div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="hide-webcam"
              />
            </div>
          ) : (
            <>
              <LottiefilesAnimation
                autoplay
                loop
                src={PartyPopper}
                styles={{ height: '200px', width: '200px' }}
              />
            </>
          )}
          <div className="chat-area ">
            <div className="position-relative">
              {interviewData?.conversationList ? (
                <div className={`chat-element-system voizo-side ${animationName} `}>
                  <TextToSpeechComponent
                    text={
                      interviewData?.conversationList[interviewData?.conversationList?.length - 1]
                        .message
                    }
                    isRecording={isRecording}
                    onComplete={() => {
                      recorderControls.startRecording();
                      setIsRecording(true);
                    }}
                  />

                  {interviewData?.status != JobStatusType.COMPLETED ? (
                    ''
                  ) : (
                    <div className=" text-center w-100">
                      <Heading2 className="">Thank You</Heading2>
                    </div>
                  )}
                  <Para fontWeight={FontWeight.REGULAR} className=" mt-1 text-start">
                    {breakLines(
                      interviewData?.conversationList[interviewData?.conversationList?.length - 1]
                        .message
                    )}
                  </Para>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div>
            {interviewData?.status != JobStatusType.COMPLETED ? (
              responseLoader ? (
                <ProgressLoader count={progressLoaderValue} />
              ) : (
                <div className="d-flex justify-content-end">{submitAnswerCard()}</div>
              )
            ) : (
              <div className="d-flex justify-content-center mt-4">
                <PrimaryButton
                  handleBtnClick={() => {
                    navigate('/');
                  }}
                >
                  Back to Home
                </PrimaryButton>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-start mb-4"></div>
        </div>
      </div>

      <InfoModal active={modalChatContent ? true : false} onClose={() => setModalChatContent(null)}>
        <div className="chat-modal-wrapper">
          <Para fontWeight={FontWeight.MEDIUM}>{modalChatContent}</Para>
        </div>
      </InfoModal>

      <AlertModal
        active={!isOnline}
        primaryBtnTitle={'Network Checking....'}
        onPrimaryBtnClick={() => {}}
      >
        <div className="text-center">
          <img src={NoInternet} className="mb-3" />
          <Heading2 className="mb-2">Your internet is broken</Heading2>
          <Para textColor="var(--textGray)" className="mb-4">
            please connect with internet and restart your interview
          </Para>
        </div>
      </AlertModal>

      <AlertModal
        active={isTimeout}
        primaryBtnTitle={'Try Again'}
        onPrimaryBtnClick={() => {
          getNextConversation(userAnswer);
          setIsTimeout(false);
        }}
        onClose={() => {
          abortController.abort();
          window.location.reload();
          setIsTimeout(false);
        }}
      >
        <div className="text-center">
          <img src={NoInternet} className="mb-3" />
          <Heading2 className="mb-2">Something went wrong</Heading2>
          <Para textColor="var(--textGray)" className="mb-4">
            Would you like to retry submitting the same answer?
          </Para>
        </div>
      </AlertModal>

      <AlertModal
        active={isRedo}
        primaryBtnTitle={'Try again'}
        onPrimaryBtnClick={() => {
          abortController.abort();
          window.location.reload();
          setRedo(false);
        }}
        onClose={() => {
          setRedo(false);
        }}
      >
        <div className="text-center">
          <img src={NoInternet} className="mb-3" />
          <Heading2 className="mb-2">Do you want to try this question again?</Heading2>
        </div>
      </AlertModal>
    </div>
  );
}
