export const GET_INTERVIEW = 'GET_INTERVIEW';
export const GET_INTERVIEW_REQUEST = 'GET_INTERVIEW_REQUEST';
export const GET_INTERVIEW_SUCCESS = 'GET_INTERVIEW_SUCCESS';
export const GET_INTERVIEW_FAILED = 'GET_INTERVIEW_FAILED';

export const GET_INTERVIEW_BY_ID = 'GET_INTERVIEW_BY_ID';
export const GET_INTERVIEW_BY_ID_REQUEST = 'GET_INTERVIEW_BY_ID_REQUEST';
export const GET_INTERVIEW_BY_ID_SUCCESS = 'GET_INTERVIEW_BY_ID_SUCCESS';
export const GET_INTERVIEW_BY_ID_FAILED = 'GET_INTERVIEW_BY_ID_FAILED';

export const START_CONVERSATION = 'START_CONVERSATION';
export const START_CONVERSATION_REQUEST = 'START_CONVERSATION_REQUEST';
export const START_CONVERSATION_SUCCESS = 'START_CONVERSATION_SUCCESS';
export const START_CONVERSATION_FAILED = 'START_CONVERSATION_FAILED';

export const NEXT_CONVERSATION = 'NEXT_CONVERSATION';
export const NEXT_CONVERSATION_REQUEST = 'NEXT_CONVERSATION_REQUEST';
export const NEXT_CONVERSATION_SUCCESS = 'NEXT_CONVERSATION_SUCCESS';
export const NEXT_CONVERSATION_FAILED = 'NEXT_CONVERSATION_FAILED';

export const GET_CONVERSATION = 'GET_CONVERSATION';
export const GET_CONVERSATION_REQUEST = 'GET_CONVERSATION_REQUEST';
export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS';
export const GET_CONVERSATION_FAILED = 'GET_CONVERSATION_FAILED';

export const TEXT_TO_SPEECH = 'TEXT_TO_SPEECH';
export const TEXT_TO_SPEECH_REQUEST = 'TEXT_TO_SPEECH_REQUEST';
export const TEXT_TO_SPEECH_SUCCESS = 'TEXT_TO_SPEECH_SUCCESS';
export const TEXT_TO_SPEECH_FAILED = 'TEXT_TO_SPEECH_FAILED';

export const INTERVIEW_SNAPSHOTS = 'INTERVIEW_SNAPSHOTS';
export const INTERVIEW_SNAPSHOTS_REQUEST = 'INTERVIEW_SNAPSHOTS_REQUEST';
export const INTERVIEW_SNAPSHOTS_SUCCESS = 'INTERVIEW_SNAPSHOTS_SUCCESS';
export const INTERVIEW_SNAPSHOTS_FAILED = 'INTERVIEW_SNAPSHOTS_FAILED';
