import { get, post, put } from '../../services/api/api';
import {
  interview,
  interviewConversation,
  interviewList,
  interviewSnapshots,
  textToSpeech
} from '../../services/api/apiConstants';

export const getInterviewListService = () => get(interviewList);

export const getInterviewService = (urlParams: string) => get(interview + urlParams);

export const startConversationService = (data: any, urlParams: string) =>
  post(interviewConversation + urlParams, data);

export const nextConversationService = (data: any, urlParams: string) =>
  put(interviewConversation + urlParams, data, 'file');

export const getConversationService = (urlParams: string) => get(interviewConversation + urlParams);

export const textToSpeechService = (data: any) => post(textToSpeech, data);

export const interviewSnapshotsService = (data: any, urlParams: string) =>
  post(interviewSnapshots + urlParams, data, 'file');
