import React from 'react';
import { Heading2, Heading3 } from '../../atoms/typography/typography';
import { SystemStatusType } from '../../../models/ui.enum';
import './styles.css';
import { StandardBtn } from '../../molecules/buttons/buttons';
interface EnvironmentCheckCardProps {
  title: string;
  status: string;
  onStatusCheck: () => void;
  img: string;
}

export default function EnvironmentCheckCard({
  title,
  status,
  onStatusCheck,
  img
}: EnvironmentCheckCardProps) {
  return (
    <div className="border rounded mt-2">
      <div className="top p-4">
        <Heading2>{title}</Heading2>
        <img
          className={(status !== SystemStatusType.TEST ? '' : 'img-disabled') + ' img-fluid mt-3'}
          src={img}
          alt=""
        />
      </div>

      <div className="p-3 d-flex justify-content-center" onClick={onStatusCheck} role="button">
        {status === SystemStatusType.TEST ? (
          <StandardBtn handleBtnClick={onStatusCheck} className="w-100 btn-warning">
            Test
          </StandardBtn>
        ) : (
          <Heading3 textColor={'var(--primary)'}>{status}</Heading3>
        )}
      </div>
    </div>
  );
}
