import { useState } from 'react';
import { PrimaryTextfield } from '../../components/molecules/textfield/textfield';
import { PrimaryButton } from '../../components/molecules/buttons/buttons';
import { Heading1, Heading2, Para } from '../../components/atoms/typography/typography';
import { ButtonType } from '../../models/ui.enum';
import { useNavigate } from 'react-router';
import { forgotPassword } from '../../firebase';
import { AlertModal } from '../../components/molecules/modal/modal';
import Check from '../../assets/check.png';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getLoaderValue } from '../../redux/states/ui/ui.services';
import { LOADING_OFF, LOADING_ON } from '../../redux/states/ui/ui.actions';
import { showAlert } from '../../utils/utils';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isResetLinkSent, setResetLinkStatus] = useState<boolean>(false);

  const isLoading = useSelector(getLoaderValue);
  const dispatch = useDispatch();

  function handleOnSubmit() {
    dispatch({ type: LOADING_ON });
    forgotPassword(email)
      .then((res: any) => {
        dispatch({ type: LOADING_OFF });
        if (res?.code === 'auth/user-not-found') {
          showAlert(dispatch, 'Please check your email and try again');
        } else if (res?.code === 'auth/too-many-requests') {
          showAlert(dispatch, 'Too many attempts. Try later');
        } else {
          setResetLinkStatus(true);
        }
      })
      .catch((e: any) => {
        console.log(e);
        dispatch({ type: LOADING_OFF });
      });
  }

  return (
    <div className="row justify-content-center text-center mt-5 p-4">
      <div className="col-lg-4">
        <div className="main-content-wrapper">
          <Heading1 className="mb-2">Forgot Password?</Heading1>
          <Para textColor="var(--textGray)" className="mb-5">
            Add your email to get reset link
          </Para>
          <br />
          <form
            onSubmit={(e) => {
              e?.preventDefault();
              handleOnSubmit();
            }}
          >
            <div className="text-start">
              <PrimaryTextfield
                onChange={(value) => {
                  setEmail(value);
                }}
                label="Email"
                placeholder="Enter your email"
                value={email}
                fieldName="forgot-password"
                type="email"
                required
              />

              <PrimaryButton
                className="w-100"
                type={ButtonType.SUBMIT}
                handleBtnClick={() => {}}
                isLoading={isLoading}
                loadingText="Please wait"
              >
                Send
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>

      <AlertModal
        active={isResetLinkSent}
        primaryBtnTitle={'Ok'}
        onPrimaryBtnClick={() => {
          navigate('/login');
        }}
        onClose={() => {
          setResetLinkStatus(false);
        }}
      >
        <div className="text-center">
          <img src={Check} className="mb-3" />
          <Heading2 className="mb-2">Password Reset Link Sent</Heading2>
          <Para textColor="var(--textGray)" className="mb-4">
            Please check your email and follow the instructions to reset your password
          </Para>
        </div>
      </AlertModal>
    </div>
  );
}
