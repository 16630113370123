import React, { Fragment, useEffect, useState } from 'react';
import { Caption, Heading1, Heading2, Para } from '../../../components/atoms/typography/typography';
import internet from '../../../../src/assets/internetspeedmeter.png';

import mic from '../../../../src/assets/micmeter.png';
import camera from '../../../assets/recorder.png';

import { PrimaryButton } from '../../../components/molecules/buttons/buttons';
import EnvironmentCheckCard from '../../../components/organisms/environment-check-card/environmentCheckCard';
import { JobStatusType, SystemStatusType } from '../../../models/ui.enum';
import './styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import useOnlineStatus from '../../../hooks/useOnlineStatus';
import NoInternet from '../../../assets/no-internet.svg';
import { playAudio, showAlert } from '../../../utils/utils';
import { Sparkle } from 'phosphor-react';
import { voizoSignatureSound } from '../../../utils/data';
import { getInterviewAction } from '../../../redux/states/interview/interview.actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export const EnvironmentCheck = () => {
  const [internetStatus, setInternetStatus] = useState<SystemStatusType>(SystemStatusType.TEST);
  const [micStatus, setMicStatus] = useState<SystemStatusType>(SystemStatusType.TEST);
  const [cameraStatus, setCameraStatus] = useState<SystemStatusType>(SystemStatusType.TEST);

  const isOnline = useOnlineStatus();
  const { interviewId } = useParams();

  const { data: interview, loading: loading } = useSelector(
    (interviewState: any) => interviewState?.interview?.interviewById
  );

  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  async function checkMicrophonePermission() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // Microphone permission is allowed
      console.log('Microphone permission is allowed.');
      stream.getTracks().forEach((track) => track.stop()); // Release the microphone stream
      setMicStatus(SystemStatusType.PERFECT);
      playAudio(voizoSignatureSound);
      return true;
    } catch (error: any) {
      // Microphone permission is denied or an error occurred
      console.error('Microphone permission is denied or an error occurred:', error);
      showAlert(
        dispatch,
        'Mic Permission Denied',
        'Please reset microphone permission to access this interview.'
      );
      setMicStatus(SystemStatusType.TEST);
      return false;
    }
  }

  async function checkWebcamPermission() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      console.log('Camera permission is allowed.');
      stream.getTracks().forEach((track) => track.stop());
      setCameraStatus(SystemStatusType.PERFECT);
      playAudio(voizoSignatureSound);
      return true;
    } catch (error: any) {
      console.error('Camera permission is denied or an error occurred:', error);
      showAlert(
        dispatch,
        'Camera Permission Denied',
        'Please reset camera permission to access this interview.'
      );
      setCameraStatus(SystemStatusType.TEST);
      return false;
    }
  }

  function checkEnvironmentStatus() {
    if (
      internetStatus != SystemStatusType.PERFECT ||
      micStatus != SystemStatusType.PERFECT ||
      cameraStatus != SystemStatusType.PERFECT
    ) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    dispatch(getInterviewAction(interviewId ?? ''));
  }, []);

  return (
    <Fragment>
      <div className="row justify-content-center  text-center mt-3 mx-5">
        <Heading1 className="mb-3 pt-4">Environment Check</Heading1>
        <Para textColor="var(--textGray)" className="mb-5">
          Before we begin, let's ensure that your system is all set to provide you with a seamless
          interview experience
        </Para>

        <div className="row justify-content-center pb-5">
          <div className="col-md-4 ">
            {
              <EnvironmentCheckCard
                title="Internet Speed"
                img={internet}
                onStatusCheck={() => {
                  setInternetStatus(
                    isOnline ? SystemStatusType.PERFECT : SystemStatusType.DISCONNECTED
                  );
                  if (isOnline) {
                    playAudio(voizoSignatureSound);
                  }
                }}
                status={internetStatus}
              />
            }
          </div>

          <div className="col-md-4 ">
            {
              <EnvironmentCheckCard
                title="Mic"
                img={mic}
                onStatusCheck={checkMicrophonePermission}
                status={micStatus}
              />
            }
          </div>

          <div className="col-md-4 ">
            {
              <EnvironmentCheckCard
                title="Camera"
                img={camera}
                onStatusCheck={checkWebcamPermission}
                status={cameraStatus}
              />
            }
          </div>
          <div className="col-md-8">
            <ul className="list-style-none text-start p-0 mt-4">
              <li>
                <Para textColor="var(--textGray)" className="mb-1">
                  <>
                    <Sparkle size={18} weight="fill" color="var(--primary)" className="me-2" />
                    <span style={{ color: 'var(--black)', fontWeight: 'bold' }}>
                      Internet Connection:
                    </span>{' '}
                    Please ensure you have a stable internet connection to avoid any interruptions.
                  </>
                </Para>
              </li>
              <li>
                <Para textColor="var(--textGray)" className="mb-1">
                  <>
                    <Sparkle size={18} weight="fill" color="var(--primary)" className="me-2" />
                    <span style={{ color: 'var(--black)', fontWeight: 'bold' }}>
                      Microphone & Speakers:
                    </span>{' '}
                    Check that your microphone and speakers are in working order for a clear two-way
                    conversation.
                  </>
                </Para>
              </li>
              <li>
                <Para textColor="var(--textGray)">
                  <>
                    <Sparkle size={18} weight="fill" color="var(--primary)" className="me-2" />
                    <span style={{ color: 'var(--black)', fontWeight: 'bold' }}>
                      Quiet Environment:
                    </span>{' '}
                    Try to find a quiet space where you won't be disturbed to help you focus during
                    the interview.
                  </>
                </Para>
              </li>
            </ul>
          </div>
        </div>

        <PrimaryButton
          className=" col-md-6 mt-2"
          isDisabled={checkEnvironmentStatus()}
          handleBtnClick={() => {
            if (interview?.status == JobStatusType.INPROGRESS) {
              navigate(`/interview/${interviewId}`);
            } else if (interview?.status == JobStatusType.INVITED) {
              navigate(`/start-interview/${interviewId}`, { replace: true });
            }
          }}
        >
          Continue
        </PrimaryButton>
      </div>
    </Fragment>
  );
};
