import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import noInternet from './assets/no-internet.svg';
import { About } from './pages/dashboard/About';
import { Home } from './pages/dashboard/Home';
import Login from './pages/auth/login';
import Navbar from './components/Navbar';
import Signup from './pages/auth/signup';
import VoizoChat from './pages/dashboard/voizo-chat/voizoChat';
import StartInterview from './pages/dashboard/start-interview/startInterview';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadData } from './redux/services/handleData/localStorage';
import { EnvironmentCheck } from './pages/dashboard/environment-check/environmentCheck';
import InterviewStructure from './pages/dashboard/interview-structure/interviewStructure';
import { getTokenData } from './firebase';
import ForgotPassword from './pages/auth/forgotPassword';
import ResetPassword from './pages/auth/resetPassword';
import { InfoModal } from './components/molecules/modal/modal';
import { Heading2, Para } from './components/atoms/typography/typography';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  getAlertHeadingValue,
  getAlertParaValue,
  getAlertValue
} from './redux/states/ui/ui.services';
import { ALERT_MODAL_OFF } from './redux/states/ui/ui.actions';

if (process.env.REACT_APP_ENV != 'stage') {
  Sentry.init({
    dsn: 'https://8c4d707c9494dd788ac6b17ecf92b59d@o4505577648029696.ingest.sentry.io/4505905950228480',
    environment: process.env.REACT_APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

const App: React.FC = () => {
  const alertState = useSelector(getAlertValue);
  const alertHeading = useSelector(getAlertHeadingValue);
  const alertPara = useSelector(getAlertParaValue);
  const dispatch = useDispatch();

  function handleAlertBtnClick() {
    dispatch({ type: ALERT_MODAL_OFF });
  }

  useEffect(() => {
    getTokenData();
  }, []);
  function routeList() {
    if (loadData('token')) {
      return (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/interview/:interviewId" element={<VoizoChat />} />

          <Route path="/start-interview/:interviewId" element={<StartInterview />} />
          <Route path="/interview-structure" element={<InterviewStructure />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Navigate to="/" replace />}></Route>
          <Route path="/environment-check/:interviewId" element={<EnvironmentCheck />} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="*" element={<Navigate to="/" replace />}></Route>
        </Routes>
      );
    }
  }
  return (
    <>
      <Navbar />
      {routeList()}
      <ToastContainer />
      <InfoModal active={alertState} onClose={handleAlertBtnClick}>
        <div className="text-center">
          <img src={noInternet} className="mb-3" />
          <Heading2 className="mb-2">{alertHeading}</Heading2>
          <Para textColor="var(--textGray)" className="mb-4">
            {alertPara}
          </Para>
        </div>
      </InfoModal>
    </>
  );
};

export default App;
