import React from 'react';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../../../components/molecules/buttons/buttons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loader from '../../../components/molecules/loader/loader';
import TextToSpeechComponent from '../../../components/audio-player/textToSpeech';
import { FontWeight, Heading2, Para } from '../../../components/atoms/typography/typography';
import { Sparkle } from 'phosphor-react';
import Cloud from '../../../assets/cloud.svg';

export default function InterviewStructure() {
  const navigate = useNavigate();

  const { data: interview, loading: loading } = useSelector(
    (interviewState: any) => interviewState?.interview?.interviewById
  );

  if (loading) return <Loader />;
  return (
    <div className="row justify-content-center">
      <>
        <TextToSpeechComponent text={interview?.interviewStructureInfo?.ssml} />
        <div className="col-xl-6 col-md-6">
          <div className="text-center start-interview-wrapper">
            <img src={Cloud} className="mb-4" />

            <Heading2 fontWeight={FontWeight.SEMIBOLD} className="mb-4">
              {interview?.interviewStructureInfo?.info?.greeting}
            </Heading2>
            <Para fontWeight={FontWeight.SEMIBOLD} className="mb-4" textColor="var(--textGray)">
              {interview?.interviewStructureInfo?.info?.welcomeText}
            </Para>
            {interview?.interviewStructureInfo?.info?.platformDetails.map((data: string) => (
              <Para className="mb-4">
                <>
                  <Sparkle size={16} weight="fill" color="var(--primary)" className="me-2" />
                  {data}
                </>
              </Para>
            ))}

            <Para fontWeight={FontWeight.SEMIBOLD} className="mb-4" textColor="var(--textGray)">
              {interview?.interviewStructureInfo?.info?.endText}
            </Para>
            <PrimaryButton
              handleBtnClick={() => {
                navigate(`/interview/${interview?._id}`);
              }}
              className="mt-4 w-100"
            >
              Start your Interview
            </PrimaryButton>
          </div>
        </div>
      </>
    </div>
  );
}
