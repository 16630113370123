export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit'
}

export enum JobStatusType {
  INVITED = 'INVITED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED'
}

export enum SystemStatusType {
  TEST = 'TEST',
  PERFECT = 'PERFECT',
  DISCONNECTED = 'DISCONNECTED'
}
