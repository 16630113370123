import React, { useEffect, useState } from 'react';
import { FontWeight, Heading2, Para } from '../../../components/atoms/typography/typography';
import { PrimaryButton } from '../../../components/molecules/buttons/buttons';
import './styles.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Cloud from '../../../assets/cloud.svg';
import TextToSpeechComponent from '../../../components/audio-player/textToSpeech';
import { useSelector } from 'react-redux';
import Loader from '../../../components/molecules/loader/loader';
import { Sparkle } from 'phosphor-react';
import { startInterviewTimer } from '../../../utils/data';
import { useDispatch } from 'react-redux';
import { getInterviewAction } from '../../../redux/states/interview/interview.actions';

export default function StartInterview() {
  const navigate = useNavigate();

  const { interviewId } = useParams();
  const dispatch: any = useDispatch();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [countdown, setCountdown] = useState<number>(startInterviewTimer.countdownTime);

  const { data: interview, loading: loading } = useSelector(
    (interviewState: any) => interviewState?.interview?.interviewById
  );

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      setIsDisabled(false);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isDisabled, countdown]);

  useEffect(() => {
    if (interview.length == 0) {
      dispatch(getInterviewAction(interviewId ?? ''));
    }
  }, []);

  const handleButtonClick = () => {
    if (!isDisabled) {
      setIsDisabled(true);

      setTimeout(() => {
        setIsDisabled(false);
      }, startInterviewTimer.waitingTime);

      navigate(`/interview/${interview?._id}`, { replace: true });
    }
  };

  if (loading) return <Loader />;
  return (
    <div className="row justify-content-center">
      <>
        <TextToSpeechComponent text={interview?.platformInfo?.ssml} />
        <div className="col-xl-6 col-md-6">
          <div className="text-center start-interview-wrapper">
            <img src={Cloud} className="mb-4" />

            <Heading2 fontWeight={FontWeight.SEMIBOLD} className="mb-4 text-capitalize">
              {interview?.platformInfo?.info?.greeting}
            </Heading2>
            <Para fontWeight={FontWeight.SEMIBOLD} className="mb-4" textColor="var(--textGray)">
              {interview?.platformInfo?.info?.welcomeText}
            </Para>
            {interview?.platformInfo?.info?.platformDetails.map((data: string) => (
              <Para className="mb-4">
                <>
                  <Sparkle size={16} weight="fill" color="var(--primary)" className="me-2" />
                  {data}
                </>
              </Para>
            ))}

            <Para className="mb-4" textColor="var(--textGray)">
              {interview?.platformInfo?.info?.endText}
            </Para>

            <PrimaryButton
              handleBtnClick={handleButtonClick}
              className={`mt-4 w-100 ${isDisabled ? 'disabled' : ''}`}
              isDisabled={isDisabled}
            >
              {isDisabled ? `Starting your Interview in (${countdown}s)` : 'Start your Interview'}
            </PrimaryButton>
          </div>
        </div>
      </>
    </div>
  );
}
