import React, { useState } from 'react';
import { PrimaryTextfield } from '../../components/molecules/textfield/textfield';
import { LinkButton, PrimaryButton } from '../../components/molecules/buttons/buttons';
import { Heading1, Heading2, Para } from '../../components/atoms/typography/typography';
import { ButtonType } from '../../models/ui.enum';
import { useNavigate } from 'react-router-dom';
import { signUpUser } from '../../firebase';
import { AlertModal } from '../../components/molecules/modal/modal';
import NoInternet from '../../assets/no-internet.svg';
import Check from '../../assets/check.png';
import { useSelector } from 'react-redux';
import { getLoaderValue } from '../../redux/states/ui/ui.services';
import { useDispatch } from 'react-redux';
import { LOADING_OFF, LOADING_ON } from '../../redux/states/ui/ui.actions';
import { Eye, EyeSlash } from 'phosphor-react';

export default function Signup() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [passwordValidationError, setPasswordValidationError] = useState('');
  const [isAlreadyUser, setAlreadyUser] = useState<boolean>(false);
  const [isVerificationLinkSent, setVerificationLinkStatus] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  function togglePasswordView() {
    setShowPassword(!showPassword);
  }
  function toggleConfirmPasswordView() {
    setShowConfirmPassword(!showConfirmPassword);
  }

  const isLoading = useSelector(getLoaderValue);
  const dispatch = useDispatch();

  //function for password validation and in response to it, give suitable errors
  function handlePassword(value: any) {
    const newPass = value;
    setPassword(newPass);

    const lowerCase = /[a-z]/g;
    const upperCase = /[A-Z]/g;
    const numbers = /[0-9]/g;

    if (newPass.length < 8) {
      setPasswordValidationError('Password should be at least 8 characters long!');
    } else if (!newPass.match(lowerCase)) {
      setPasswordValidationError('Password should contain at least one LowerCase letter!');
    } else if (!newPass.match(upperCase)) {
      setPasswordValidationError('Password should contain at least one UpperCase letter!');
    } else if (!newPass.match(numbers)) {
      setPasswordValidationError('Password should contain at least one Number!');
    } else {
      setPasswordValidationError('');
    }
  }

  const ERROR_CODES = {
    EMAIL_IN_USE: 'auth/email-already-in-use'
  };

  function handleSignUpResponse(res: any) {
    dispatch({ type: LOADING_OFF });
    if (res?.code === ERROR_CODES.EMAIL_IN_USE) {
      setAlreadyUser(true);
      return;
    }

    if (!res?.emailVerified) {
      setVerificationLinkStatus(true);
      return;
    }

    navigate('/');
  }

  function handleOnSubmit() {
    if (passwordValidationError) return;

    if (password !== confirmPassword) {
      setConfirmPasswordError('Confirm password should be same as the password field');
      return;
    } else {
      setConfirmPasswordError('');
    }

    dispatch({ type: LOADING_ON });
    const name: string = firstName.trim() + ' ' + lastName.trim();
    signUpUser(email, password, name)
      .then(handleSignUpResponse)
      .catch((e: any) => {
        console.log(e);
        dispatch({ type: LOADING_OFF });
      });
  }

  return (
    <div className="row justify-content-center text-center mt-4 p-4">
      <div className="col-lg-4">
        <form
          onSubmit={(e) => {
            e?.preventDefault();
            handleOnSubmit();
          }}
        >
          <div className="main-content-wrapper">
            <Heading1 className="mb-2">Create your account</Heading1>
            <Para textColor="var(--textGray)" className="mb-5">
              Welcome back! Please enter your details.
            </Para>
            <br />

            <div className="text-start">
              <div className="row g-0">
                <div className="col-md-6">
                  <PrimaryTextfield
                    onChange={(value) => setFirstName(value)}
                    label="First Name"
                    placeholder="Enter your first name"
                    value={firstName}
                    fieldName=""
                    type="text"
                    required
                    class="me-md-2"
                  />
                </div>

                <div className="col-md-6">
                  <PrimaryTextfield
                    onChange={(value) => setLastName(value)}
                    label="Last Name"
                    placeholder="Enter your last name"
                    value={lastName}
                    fieldName=""
                    type="text"
                    required
                    class="ms-md-2"
                  />
                </div>
              </div>

              <PrimaryTextfield
                onChange={(value) => setEmail(value)}
                label="Email"
                placeholder="Enter your email"
                value={email}
                fieldName=""
                type="email"
                required
              />
              <PrimaryTextfield
                onChange={handlePassword}
                label="Password"
                placeholder="Password"
                value={password}
                fieldName=""
                type={showPassword ? 'text' : 'password'}
                required
                error={passwordValidationError}
                suffixIcon={showPassword ? <Eye size={24} /> : <EyeSlash size={24} />}
                onSuffixIconClick={togglePasswordView}
              />

              <PrimaryTextfield
                onChange={(value) => setConfirmPassword(value)}
                label="Confirm Password"
                placeholder="Re-enter Password"
                value={confirmPassword}
                fieldName=""
                type={showConfirmPassword ? 'text' : 'password'}
                error={confirmPasswordError}
                required
                suffixIcon={showConfirmPassword ? <Eye size={24} /> : <EyeSlash size={24} />}
                onSuffixIconClick={toggleConfirmPasswordView}
              />
              <br />
              <PrimaryButton
                className="w-100"
                type={ButtonType.SUBMIT}
                handleBtnClick={() => {}}
                isLoading={isLoading}
                loadingText="Please wait"
              >
                Sign Up
              </PrimaryButton>

              <div className="d-flex align-items-center justify-content-center mt-4">
                <Para>Already have an account? </Para>
                <LinkButton
                  handleBtnClick={() => {
                    navigate('/');
                  }}
                >
                  LogIn
                </LinkButton>
              </div>
            </div>
          </div>
        </form>
      </div>

      <AlertModal
        active={isAlreadyUser}
        primaryBtnTitle={'Login'}
        onPrimaryBtnClick={() => {
          navigate('/');
        }}
        onClose={() => {
          setAlreadyUser(false);
        }}
      >
        <div className="text-center">
          <img src={NoInternet} className="mb-3" />
          <Heading2 className="mb-2">Account already exist</Heading2>
          <Para textColor="var(--textGray)" className="mb-4">
            Your account already exist. Please try login to continue
          </Para>
        </div>
      </AlertModal>

      <AlertModal
        active={isVerificationLinkSent}
        primaryBtnTitle={'Ok'}
        onPrimaryBtnClick={() => {
          setVerificationLinkStatus(false);
        }}
        onClose={() => {
          setVerificationLinkStatus(false);
        }}
      >
        <div className="text-center">
          <img src={Check} className="mb-3" />
          <Heading2 className="mb-2">Email Verification Link Sent</Heading2>
          <Para textColor="var(--textGray)" className="mb-4">
            Please check your email and follow the instructions to verify email
          </Para>
        </div>
      </AlertModal>
    </div>
  );
}
