import { Dispatch } from 'redux';
import { handleFailure, handleRequest, handleSuccess } from '../../services/handleData/handleData';
import { GET_USER_FAILED, GET_USER_REQUEST, GET_USER_SUCCESS } from './candidates.constant';
import { getUserService } from './candidates.services';

export const getUserAction = () => async (dispatch: Dispatch) => {
  dispatch(handleRequest(GET_USER_REQUEST));
  try {
    const res = await getUserService();
    dispatch(handleSuccess(GET_USER_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_USER_FAILED, ex?.message));
    return ex;
  }
};
