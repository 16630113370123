export const interviewList = 'api/interview/all';

export const interview = 'api/interview/';

export const interviewConversation = 'api/interview/conversation';

export const user = 'api/user';

export const textToSpeech = 'api/user/text-to-speech';

export const interviewSnapshots = 'api/interview/upload-interview-snapshot';
